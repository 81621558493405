import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LeadState } from './lead.state';
export const LEAD_STATE_NAME = 'lead';
const selectLeadState = createFeatureSelector<LeadState>(LEAD_STATE_NAME);
export const selectLeadCount = createSelector(
  selectLeadState,
  state => state.total
);
const selectLeads = createSelector(selectLeadState, ({ data }) => data);
const selectFilter = createSelector(selectLeadState, ({ filter }) => filter);
const selectPagination = createSelector(
  selectLeadState,
  ({ pagination }) => pagination
);
const selectInterest = createSelector(
  selectLeadState,
  ({ interest }) => interest
);
const selectPaginationAndFilter = createSelector(
  selectLeadState,
  ({ pagination, filter }) => ({ filter, pagination })
);
const selectTotal = createSelector(selectLeadState, ({ total }) => total);
export default {
  selectFilter,
  selectLeads,
  selectTotal,
  selectPagination,
  selectInterest,
  selectPaginationAndFilter,
};
