import { ApiResponse, Group, Lead, Pagination, LeadPageModel } from '@models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const leadActions = createActionGroup({
  source: '[Lead page]',
  events: {
    'fetch Lead': emptyProps(),
    'fetch lead success': props<{ data: ApiResponse<Lead[]> }>(),
    'fetch interest': emptyProps(),
    'fetch interest success': props<{ data: string[] }>(),
    'assign leads': props<{
      data: { ids: Lead['id'][]; groupId: Group['id'] };
    }>(),
    'update filter': props<{ data: LeadPageModel.filter }>(),
    'update Pagination': props<{ data: Pagination }>(),
    'export to csv': emptyProps(),
    'send Message': props<{
      data: {
        ids: Lead['id'][];
        message: string;
        source: ('message' | 'mail')[];
      };
      ref?: string;
    }>(),
  },
});
export default leadActions;
